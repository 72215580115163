var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade-in-up"
    }
  }, [_c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }